//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ContentMenu from '@/components/basics/ContentMenu';
import FrequentActions from '@/components/elements/FrequentActions';
export default {
  components: { ContentMenu, FrequentActions },
  data() {
    return {
      links: [
        { name: 'Account', to: '/dashboard' },
        { name: 'Billing', to: '/dashboard/billing' },
        { name: 'Team', to: '/dashboard/team' }
      ]
    };
  },
  computed: {
    userFirstName() {
      const names = this.$auth.user.name.split(' ');
      return names.length > 0 ? names[0] : '';
    },
    selectedTitle() {
      for (const link of this.links) {
        if (link.to === this.$store.state.dashboardNav.currentPage) {
          return link.name;
        }
      }
      return '';
    }
  },
  watch: {
    $route() {
      this.$store.commit('dashboardNav/setCurrentPage', this.$route.path);
    }
  },
  methods: {
    isActive(page) {
      return this.$store.state.dashboardNav.currentPage === page;
    },
    handleClick() {
      this.closeContentMenu();
    },
    closeContentMenu() {
      if (this.$refs.contentMenu) {
        this.$refs.contentMenu.close();
      }
    },
    async logout() {
      this.closeContentMenu();
      this.$nextTick(() => {
        this.$store.commit('user/clear');
      });
      await this.$auth.logout();
    }
  }
};
