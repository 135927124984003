import { render, staticRenderFns } from "./DashboardNav.vue?vue&type=template&id=3c3d288e&scoped=true&"
import script from "./DashboardNav.vue?vue&type=script&lang=js&"
export * from "./DashboardNav.vue?vue&type=script&lang=js&"
import style0 from "./DashboardNav.vue?vue&type=style&index=0&id=3c3d288e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c3d288e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FrequentActions: require('/opt/build/repo/components/elements/FrequentActions.vue').default,ContentMenu: require('/opt/build/repo/components/basics/ContentMenu.vue').default})
